import { Component, OnInit } from '@angular/core';
import { AcopiosService } from 'src/app/services/acopios/acopios.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-acopios',
  templateUrl: './acopios.component.html',
  styleUrls: ['./acopios.component.scss']
})
export class AcopiosComponent implements OnInit {

  public IMG_HOST = environment.API_IMAGEN;
  public cargandoAcopios: boolean = true;
  public sinAcopios: boolean = false;
  public slider: any = [];
  public acopios: any = [];

  constructor(
    private acopios_service: AcopiosService
  ) { }

  ngOnInit(): void {
    this.obtenerSlider();
    this.obtenerAcopios();
  }

  obtenerSlider = () => {
    this.acopios_service.getSlider().subscribe(respuesta => {
      this.slider = respuesta;
    });
  }

  obtenerAcopios = () => {
    this.acopios_service.getAcopios().subscribe(respuesta => {
      this.acopios = respuesta;
      if (this.acopios) {
        this.sinAcopios = false;
        this.cargandoAcopios = false;
      } else {
        this.sinAcopios = true;
        this.cargandoAcopios = false;
      }
    });
  }
}
