import { Component, OnInit } from '@angular/core';
import { HashLocationStrategy, Location, LocationStrategy } from '@angular/common';
import { ConfiguracionService } from 'src/app/services/configuracion/configuracion.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-navbar',
  providers: [Location, { provide: LocationStrategy, useClass: HashLocationStrategy }],
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  public redes: any = [];
  public WhatsApp_host = 'https://api.whatsapp.com/send?phone=';
  public IMG_HOST = environment.API_IMAGEN;

  constructor(
    private configuracion_service: ConfiguracionService
  ) { }

  ngOnInit(): void {
    this.obtenerRedes();
  }

  obtenerRedes = () => {
    this.configuracion_service.getRedesSociales().subscribe(respuesta => {
      this.redes = respuesta[0];
    });
  }

}
