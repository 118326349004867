import { Component, OnInit } from '@angular/core';
import { ConfiguracionService } from 'src/app/services/configuracion/configuracion.service';
import { VinculacionService } from 'src/app/services/vinculacion/vinculacion.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.scss']
})
export class EmpresaComponent implements OnInit {

  public IMG_HOST = environment.API_IMAGEN;
  public cargandoEmpresas: boolean = true;
  public sinEmpresas: boolean = false;
  public cargandoContenido: boolean = true;
  public empresas: any = [];
  public contenido: any = {};
  public banner;

  constructor(
    private empresa_service: VinculacionService,
    private configuracion_service: ConfiguracionService,
  ) { }

  ngOnInit(): void {
    this.obtenerContenido();
    this.obtenerEmpresas();
    this.obtenerBanner();
  }

  obtenerBanner = () => {
    this.configuracion_service.getBanners("'Avisos'").subscribe(respuesta => {
      this.banner = respuesta[0];
    });
  }

  obtenerContenido = () => {
    this.empresa_service.getEmpresasContenido().subscribe(respuesta => {
      this.contenido = respuesta[0];
      if (this.contenido) {
        this.cargandoContenido = false;
      } else {
        this.cargandoContenido = false;
      }
    });
  }

  obtenerEmpresas = () => {
    this.empresa_service.getEmpresas().subscribe(respuesta => {
      this.empresas = respuesta;
      if (this.empresas) {
        this.sinEmpresas = false;
        this.cargandoEmpresas = false;
      } else {
        this.sinEmpresas = true;
        this.cargandoEmpresas = false;
      }
    });
  }

}
