<div class="banner-seccion backgroundBanner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="bannerContainerStyles">
                    <h2>Contáctanos</h2>
                </div>
            </div>
        </div>
    </div>
</div>



<div *ngFor="let contacto of contactos" class="transportation-area">
    <div class="container-fluid">
        <div class="row no-gutters">
            <div class="col-lg-6 col-md-12">
                <div class="transportation-form ptb-30">
                    <div class="section-title">
                        <!-- <span>Transportation</span> -->
                        <h2>{{contacto.titulo}}</h2>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <h6> <i class='bx bxs-location-plus'></i> Ubicación</h6>
                            <p>{{contacto.ubicacion}}</p>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-12">
                            <h6> <i class='bx bx-show'></i> Horario</h6>
                            <p *ngFor="let hora of contacto.horarios">
                                {{hora.dias}}: {{hora.hora}}
                            </p>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-12">
                            <h6> <i class='bx bxs-phone'></i> Teléfonos</h6>
                            <p *ngFor="let tel of contacto.telefonos">
                                {{tel.telefono}}
                            </p>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-12">
                            <h6><i class='bx bxs-envelope'></i>
                                Correos</h6>
                            <p *ngFor="let correo of contacto.correos">
                                {{correo.titulo}}: <a [href]="MAIL + correo.correo"> {{correo.correo}}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <!-- <div class="popup-video"> -->
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="map-responsive">
                            <div [innerHtml]="contacto.maps | safeHtml"></div>
                        </div>
                    </div>
                </div>
                <!-- </div> -->
            </div>
            <hr>
        </div>
    </div>
</div>