import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})

export class FloracionesService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getContenido() {
    return this.http.get(`${this.API_URI}/floraciones`);
  }

  getPeriodos() {
    return this.http.get(`${this.API_URI}/floraciones/periodos`);
  }

  getImagenes(id) {
    return this.http.get(`${this.API_URI}/floraciones/imagenes/${id}`);
  }

  getFloraciones(id) {
    return this.http.get(`${this.API_URI}/floraciones/floraciones/${id}`);
  }

}
