import { Component, OnInit } from '@angular/core';
import { AcopiosService } from 'src/app/services/acopios/acopios.service';
import { ConfiguracionService } from 'src/app/services/configuracion/configuracion.service';
import { VinculacionService } from 'src/app/services/vinculacion/vinculacion.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-sociedad',
  templateUrl: './sociedad.component.html',
  styleUrls: ['./sociedad.component.scss']
})
export class SociedadComponent implements OnInit {

  public IMG_HOST = environment.API_IMAGEN;
  public cargandoContenido: boolean = true;
  public cargandoSociedades: boolean = true;
  public sinSociedades: boolean = false;
  public sociedades: any = [];
  public contenido: any = {};
  public banner;

  constructor(
    private sociedad_service: VinculacionService,
    private configuracion_service: ConfiguracionService,
  ) { }

  ngOnInit(): void {
    this.obtenerContenido();
    this.obtenerSociedades();
    this.obtenerBanner();
  }

  obtenerBanner = () => {
    this.configuracion_service.getBanners("'Sociedad'").subscribe(respuesta => {
      this.banner = respuesta[0];
    });
  }

  obtenerContenido = () => {
    this.sociedad_service.getSociedadesContenido().subscribe(respuesta => {
      this.contenido = respuesta[0];
      if (this.contenido) {
        this.cargandoContenido = false;
      } else {
        this.cargandoContenido = false;
      }
    });
  }

  obtenerSociedades = () => {
    this.sociedad_service.getSociedades().subscribe(respuesta => {
      this.sociedades = respuesta;
      if (this.sociedades) {
        this.sinSociedades = false;
        this.cargandoSociedades = false;
      } else {
        this.sinSociedades = true;
        this.cargandoSociedades = false;
      }
    });
  }

}
