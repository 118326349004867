import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})

export class ProductosService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getMarcas() {
    return this.http.get(`${this.API_URI}/productos/marcas`);
  }

  getClasificaciones(idClasificacion) {
    return this.http.get(`${this.API_URI}/productos/clasificaciones/${idClasificacion}`);
  }

  getProductos(idMarca, idClasificacion) {
    return this.http.get(`${this.API_URI}/productos/${idMarca}/${idClasificacion}`);
  }

}
