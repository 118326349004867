import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BienvenidaService } from 'src/app/services/nosotros/bienvenida.service';
import { NosotrosService } from 'src/app/services/nosotros/nosotros.service';
import { PortadaService } from 'src/app/services/nosotros/portada.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-nosotros',
  templateUrl: './nosotros.component.html',
  styleUrls: ['./nosotros.component.scss']
})
export class NosotrosComponent implements OnInit {

  public cargandoBienvenida: boolean = true;
  public sinBienvenida: boolean = false;
  public bienvenida: any = {};

  public cargandoMisionVision: boolean = true;
  public sinMisionVision: boolean = false;
  public misionVision: any = [];

  public cargandoValores: boolean = true;
  public sinValores: boolean = false;
  public valores: any = [];

  public cargandoNosotros: boolean = true;
  public sinNosotros: boolean = false;
  public nosotros: any = [];

  public portada: any = {};
  public slider: any = [];
  public portadaSlider;

  public IMG_HOST = environment.API_IMAGEN;
  public backGroundImage = {};

  dimensionPantalla: boolean = false;

  constructor(
    private viewportScroller: ViewportScroller,
    private portada_service: PortadaService,
    private bienvenida_service: BienvenidaService,
    private nosotros_service: NosotrosService,
  ) { }

  public onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnInit(): void {
    if(window.innerWidth <= 768){
        this.dimensionPantalla = true;
    }else{


    }
    this.obtenerPortada();
    this.obtenerSlider();
    this.obtenerBienvenida();
    this.obtenerNosotros();
    this.obtenerMisionVision();
    this.obtenerValores();
  }

  obtenerPortada = () => {
    this.portada_service.getPortada().subscribe(respuesta => {
      this.portada = respuesta[0];
    });
    this.portada_service.getPortadaSlider().subscribe(respuesta => {
      this.portadaSlider = respuesta;
    });
  }


  obtenerBienvenida = () => {
    this.bienvenida_service.getBienvenida().subscribe(respuesta => {
      this.bienvenida = respuesta[0];
      if (this.bienvenida) {
        this.sinBienvenida = false;
        this.cargandoBienvenida = false;
      } else {
        this.sinBienvenida = true;
        this.cargandoBienvenida = false;
      }
    });
  }

  obtenerMisionVision = () => {
    this.nosotros_service.getMisionVision().subscribe(respuesta => {
      this.misionVision = respuesta;
      if (this.misionVision) {
        this.sinMisionVision = false;
        this.cargandoMisionVision = false;
      } else {
        this.sinMisionVision = true;
        this.cargandoMisionVision = false;
      }
    });
  }

  obtenerValores = () => {
    this.nosotros_service.getValores().subscribe(respuesta => {
      this.valores = respuesta;
      if (this.valores) {
        this.sinValores = false;
        this.cargandoValores = false;
      } else {
        this.sinValores = true;
        this.cargandoValores = false;
      }
    });
  }

  obtenerNosotros = () => {
    this.nosotros_service.getNosotros().subscribe(respuesta => {
      this.nosotros = respuesta;
      if (this.nosotros) {
        this.sinNosotros = false;
        this.cargandoNosotros = false;
      } else {
        this.sinNosotros = true;
        this.cargandoNosotros = false;
      }
    });
  }

  obtenerSlider = () => {
    this.nosotros_service.getSlider().subscribe(respuesta => {
      this.slider = respuesta;
    });
  }

}
