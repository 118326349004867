import { Component, OnInit } from '@angular/core';
import { ConfiguracionService } from 'src/app/services/configuracion/configuracion.service';
import { ProductosService } from 'src/app/services/productos/productos.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-marcas',
  templateUrl: './marcas.component.html',
  styleUrls: ['./marcas.component.scss']
})
export class MarcasComponent implements OnInit {

  public IMG_HOST = environment.API_IMAGEN;
  public cargandoMarcas: boolean = true;
  public sinMarcas: boolean = false;
  public marcas: any = [];
  public banner: any = {};

  public WhatsApp_host = 'https://api.whatsapp.com/send?phone=';
  public numeroWhats: any = [];

  constructor(
    private productos_service: ProductosService,
    private redes_service: ConfiguracionService,
  ) { } 

  // funcion transition cards
   async cardTransition(data : any){   
    await data; 
    let informationCard = null; 
    let imageCard = null; 
    let cardC = null; 
    setTimeout(()=>{  
      informationCard = document.querySelectorAll(".transitionCardXD"); 
      let arrayCards = [...informationCard]; 
      imageCard = document.querySelectorAll(".transitionImage"); 
      cardC = document.querySelectorAll(".cardCustome"); 
      for(let i in arrayCards){  
        imageCard[i].addEventListener("mouseover", ()=>{ 
          cardC[i].style.setProperty("--play", "running"); 
      });  
        informationCard[i].addEventListener("mouseout", ()=>{ 
        informationCard[i].classList.remove("cardActive"); 
        cardC[i].style.setProperty("--play", "paused");
      });  
      }; 
    }, 700); 
  } 
  // funcion transitions cards

  ngOnInit(): void {
    this.obtenermarcas();
    this.obtenerWhats();
    this.obtenerBanner(); 
    // this.cardTransition(); 
  }

  obtenerBanner = () => {
    this.redes_service.getBanners("'Productos'").subscribe(respuesta => {
      this.banner = respuesta[0];
    });
  }

  obtenerWhats = () => {
    this.redes_service.getRedesSociales().subscribe(respuesta => {
      this.numeroWhats = respuesta[0];
    });
  }

  obtenermarcas = () => {
    this.productos_service.getMarcas().subscribe(respuesta => {
      this.marcas = respuesta;
      if (this.marcas) {
        this.cardTransition(this.marcas)
        this.sinMarcas = false;
        this.cargandoMarcas = false;
      } else {
        this.sinMarcas = true;
        this.cargandoMarcas = false;
      }
    });
  }

}
