import { Component, OnInit } from '@angular/core';
import { AvisosPrivacidadService } from 'src/app/services/avisos/avisosPrivacidad.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-avisos-privacidad',
  templateUrl: './avisos.component.html',
  styleUrls: ['./avisos.component.scss']
})
export class AvisosPrivacidadComponent implements OnInit {

  public IMG_HOST = environment.API_IMAGEN;
  public cargandoAvisos: boolean = true;
  public sinAvisos: boolean = false;
  public avisos: any = [];

  constructor(
    private avisos_service: AvisosPrivacidadService
  ) { }

  ngOnInit(): void {
    this.obtenerAvisos();
  }

  obtenerAvisos = () => {
    this.avisos_service.getAvisosPrivacidad().subscribe(respuesta => {
      this.avisos = respuesta;
      if (this.avisos) {
        this.sinAvisos = false;
        this.cargandoAvisos = false;
      } else {
        this.sinAvisos = true;
        this.cargandoAvisos = false;
      }
    });
  }

}
