<div class="page-banner" [ngStyle]=" {'background-image': 'url(' + IMG_HOST + banner.rutaImagen + ')' }">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="bannerContainerStyles">
          <h2>Galería</h2>
        </div>
      </div>
    </div>
  </div>
</div> 

<div class="position-relative d-flex flex-column justify-content-center align-items-center "> 
<div class="ptb-30 w-100 p-0 pt-4">
  <div class="container p-0">
    <div class="btn-group d-flex flex-wrap" role="group" ngbRadioGroup name="radioBasic" [(ngModel)]="model">
      <label *ngFor="let clasificacion of clasificaciones" ngbButtonLabel class="btn-warning buttonCustomeStyles"
        (click)="obtenerGaleria(clasificacion.idClasificacion)">
        <input ngbButton type="radio" class="btn-check" [value]="clasificacion.idClasificacion">
        {{clasificacion.nombre}}
      </label>
    </div>
    <hr>
  </div>
</div>
<div class="team-area ptb-30" *ngIf="trueVideo != '' ">
  <div class="container">
    <div class="d-flex flex-wrap gap-4 justify-content-center ">
                <div (click)="openModal(galeria, i)" *ngFor="let gal of galeria; index as i" data-Content="content" class="col-lg-3 col-md-3 card shadow cardHoverStyle DataClassSelect rounded-customeG">
                      <!-- <div class="text-center p-3 position-relative cardHover">
                      <h5>{{gal.titulo}}</h5>
                      <p [innerHtml]="gal.descripcion | safeHtml"></p>
                      <button class="btn btn-xs btn-warning" (click)="open(content, gal)">Abrir</button>
                      </div>  --> 
                    <img loading="lazy" [src]="IMG_HOST + gal.rutaImagen" alt="image" class="widthCustome rounded-customeG shadow">
                 </div> 
              </div>
            </div>
        </div> 
        <!-- -------------------seccion de videos --------------------------- -->
      <div class="container mb-5" *ngIf="trueVideo === ''" >  
        <div class="d-flex gap-4 flex-wrap justify-content-center"> 
          <div *ngFor="let video of galeria" [innerHtml]="video.descripcion| safeHtml" class="d-flex justify-content-center align-items-center DataClassSelect"> 
          </div> 
        </div>
      </div>
      <!-- --------------------seccion de videos---------------------------- -->  
      <div #modalGal class="newModal customeDimensions px-4 py-1 bg-light border border-3 border-warning ">  
        <div class="d-flex justify-content-end m-0"> 
          <div (click)="closeModal()" class="buttonCloseModalStyle"><i class='bx bx-x h4 bg-danger text-light roudend-3 m-0'></i></div>
        </div>
        <div class="customeDimencionContainerImg rounded-3 mb-3">
          <img #imagenModal alt="imagen galeria" class="customeDimencionImg">
        </div>
        <div> 
          <h4 #titleInformation class="h5"></h4>
          <div #textInformation class="overflow"></div>
        </div>
      </div> 
    </div>
      <!-- <ng-template #content let-modal class="w-100">
   <div class="modal-header headerCustomeStyles">
    <h6 class="modal-title" id="modal-basic-title">{{foto.titulo}}</h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body bodyCustomeStyles">
    <div class="row align-items-center">
      <div class="w-100">
        <div class="imagen mb-2">
          <img loading="lazy" class="img-fluid rounded mx-auto d-block" [src]="IMG_HOST + foto.rutaImagen">
        </div>
        <p styel="font-size: 12px;" [innerHtml]="foto.descripcion | safeHtml"></p>
      </div>
    </div>
  </div>
  <br>
</ng-template> -->

  <!-- <div class="team-text">
            <h3>{{gal.titulo}}</h3>
            <p [innerHtml]="gal.descripcion | safeHtml"></p>
          </div> -->
