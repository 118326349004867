import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { async } from 'rxjs/internal/scheduler/async';
import { ConfiguracionService } from 'src/app/services/configuracion/configuracion.service';
import { ProductosService } from 'src/app/services/productos/productos.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.scss']
})
export class ProductosComponent implements OnInit {


  public idMarca;
  public IMG_HOST = environment.API_IMAGEN;
  public cargandoClasificaciones: boolean = true;
  public sinClasificaciones: boolean = false;
  public clasificaciones: any = [];
  public banner: any = {};
  public marcas: any = [];

  public cargandoProductos: boolean = true;
  public sinProductos: boolean = false;
  public productos: any = [];
  public WhatsApp_host = 'https://api.whatsapp.com/send?phone=';
  public numeroWhats: any = [];

  constructor(
    private _router: ActivatedRoute,
    private productos_service: ProductosService,
    private redes_service: ConfiguracionService,
  ) {
    this._router.params.subscribe(res => this.idMarca = res.idMarca);
  }

  ngOnInit(): void {
    this.obtenerClasificaciones();
    this.obtenerWhats();
    this.obtenerBanner();
    this.obtenermarcas();
  }

  MenuButtonSelect = async (res) => { 
    await res; 
    setTimeout(()=>{ 
      let labelCheck = document.querySelectorAll(".labelCheck"); 
      labelCheck.forEach((label, index) =>{   
        if(this.idMarca == index+1) { 
          label.classList.add("active"); 
        }else{ 
          label.classList.remove("active"); 
        }
      })
    }, 200);
  }
  obtenerBanner = () => {
    this.redes_service.getBanners("'Productos'").subscribe(respuesta => {
      this.banner = respuesta[0];
    });
  }

  obtenerWhats = () => {
    this.redes_service.getRedesSociales().subscribe(respuesta => {
      this.numeroWhats = respuesta[0];
    });
  }

  obtenerClasificaciones = () => { 
    this.productos_service.getClasificaciones(this.idMarca).subscribe(respuesta => {
      this.clasificaciones = respuesta; 
      this.MenuButtonSelect(respuesta); 
      if (this.clasificaciones) {
        this.sinClasificaciones = false;
        this.cargandoClasificaciones = false;
        this.clasificaciones.forEach((element) => {
          this.productos_service.getProductos(this.idMarca, element.idClasificacion).subscribe(response => {
            element.productos = response;
            if (element.productos) {
              this.sinProductos = false;
              this.cargandoProductos = false;
            } else {
              this.sinProductos = true;
              this.cargandoProductos = false;
            }
          })
        });
      } else {
        this.sinClasificaciones = true;
        this.cargandoClasificaciones = false;
      }
    });
  }
  obtenermarcas = () => {
    this.productos_service.getMarcas().subscribe(respuesta => {
      this.marcas = respuesta;  
    });
  }

  cambios(id){ 
    this.idMarca = id; 
    this.obtenerClasificaciones();  
  }; 
}


