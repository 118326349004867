import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VinculacionService } from 'src/app/services/vinculacion/vinculacion.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-detalle-empresa',
  templateUrl: './detalle-empresa.component.html',
  styleUrls: ['./detalle-empresa.component.scss']
})
export class DetalleEmpresaComponent implements OnInit {

  public idEmpresa;
  public IMG_HOST = environment.API_IMAGEN;
  public cargandoDetalle: boolean = true;
  public sinDetalle: boolean = false;
  public detalleEmpresa: any = {};
  public imagenes: any = [];

  constructor(
    private _router: ActivatedRoute,
    private empresa_service: VinculacionService
  ) {
    this._router.params.subscribe(res => this.idEmpresa = res.idEmpresa);
  }

  ngOnInit(): void {
    this.obtenerImagenes(this.idEmpresa);
    this.obtenerdetalleEmpresa(this.idEmpresa);
  }

  obtenerImagenes = (idEmpresa) => {
    this.empresa_service.getImagenesEmpresa(idEmpresa).subscribe(respuesta => {
      this.imagenes = respuesta;
    });
  }

  obtenerdetalleEmpresa = (idEmpresa) => {
    this.empresa_service.getDetalleEmpresa(idEmpresa).subscribe(respuesta => {
      this.detalleEmpresa = respuesta[0];
      if (this.detalleEmpresa) {
        this.sinDetalle = false;
        this.cargandoDetalle = false;
      } else {
        this.sinDetalle = true;
        this.cargandoDetalle = false;
      }
    });
  }

}
