import { Component, OnInit } from '@angular/core';
import { CertificacionesService } from 'src/app/services/certificaciones/certificaciones.service';
import { ConfiguracionService } from 'src/app/services/configuracion/configuracion.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-certificaciones',
  templateUrl: './certificaciones.component.html',
  styleUrls: ['./certificaciones.component.scss']
})
export class CertificacionesComponent implements OnInit {

  public IMG_HOST = environment.API_IMAGEN;
  public cargandoCertificaciones: boolean = true;
  public sinCertificaciones: boolean = false;
  public certificaciones: any = [];
  public banner: any = {};

  constructor(
    private certificaciones_service: CertificacionesService,
    private configuracion: ConfiguracionService,
  ) { }

  ngOnInit(): void {
    this.obtenerBanner();
    this.obtenerCertificaciones();
  }

  obtenerBanner = () => {
    this.configuracion.getBanners("'Certificaciones'").subscribe(respuesta => {
      this.banner = respuesta[0];
    });
  }

  obtenerCertificaciones = () => {
    this.certificaciones_service.getCertificaciones().subscribe(respuesta => {
      this.certificaciones = respuesta;
      if (this.certificaciones) {
        this.sinCertificaciones = false;
        this.cargandoCertificaciones = false;
      } else {
        this.sinCertificaciones = true;
        this.cargandoCertificaciones = false;
      }
    });
  }

}
