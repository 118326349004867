import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VinculacionService } from 'src/app/services/vinculacion/vinculacion.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-detalle-sociedad',
  templateUrl: './detalle-sociedad.component.html', 
  styleUrls: ['./detalle-sociedad.component.scss'], 
})
export class DetalleSociedadComponent implements OnInit {

  public idSociedad;
  public IMG_HOST = environment.API_IMAGEN;
  public cargandoDetalle: boolean = true;
  public sinDetalle: boolean = false;
  public detalleSociedad: any = {};
  public imagenes: any = [];

  constructor(
    private _router: ActivatedRoute,
    private sociedad_service: VinculacionService
  ) {
    this._router.params.subscribe(res => this.idSociedad = res.idSociedad);
  }

  ngOnInit(): void {
    this.obtenerImagenes(this.idSociedad);
    this.obtenerdetalleSociedad(this.idSociedad);
  }

  obtenerImagenes = (idSociedad) => {
    this.sociedad_service.getImagenesSociedad(idSociedad).subscribe(respuesta => {
      this.imagenes = respuesta;
    });
  }

  obtenerdetalleSociedad = (idSociedad) => {
    this.sociedad_service.getDetalleSociedad(idSociedad).subscribe(respuesta => {
      this.detalleSociedad = respuesta[0];
      if (this.detalleSociedad) {
        this.sinDetalle = false;
        this.cargandoDetalle = false;
      } else {
        this.sinDetalle = true;
        this.cargandoDetalle = false;
      }
    });
  }

}
