<div class="page-banner" [ngStyle]=" {'background-image': 'url(' + IMG_HOST + banner.rutaImagen + ')' }">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="bannerContainerStyles">
                    <h2>Productos</h2>
                    <!-- <ul>
                        <li>
                            <a [href]="numeroWhats.tiendaFacebook" target="_blank">
                                <span>Visitar tienda <br> <i class='bx bx-store'></i></span>
                            </a>
                        </li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="freight-area freight-area-two pt-100 pb-70">

    <div class="btn-whatsapp">
        <a [href]="WhatsApp_host + numeroWhats.whatsApp" target="_blank" title="Abrir WhatsApp">
            <img loading="lazy" src="../../../../assets/img/whatsapp-logo-vector.png" alt="WhatsApp">
        </a>
    </div>

    <div *ngIf="cargandoMarcas">
        <i class='bx bx-loader-circle'></i> Cargando...
    </div>
    <div *ngIf="sinMarcas && !cargandoMarcas" style="text-align: center;">
        <h1><i class='bx bx-wrench'></i></h1>
        <h3>Sin contenido</h3>
    </div>
    <div *ngIf="!sinMarcas && !cargandoMarcas" class="container">


        <div class="services-area ptb-30">
            <div class="container">
                <div class="section-title">
                    <!-- <span>Our Services</span> -->
                    <h2>Nuestras marcas</h2>
                </div>

                <div class="row">
                    <div class="col-lg-12 col-sm-6 rounded-3 w-100"> 
                          <div class="d-flex w-100 justify-content-around flex-wrap" style="width: 18rem;"> 
                            <div *ngFor="let marca of marcas; index as i" class="transitionCardXD d-flex justify-content-center align-items-center containerCards position-relative cardCustome overflow-hidden mb-3"> 
                            <!-- card transition component -->
                            <figure class="cardsT" >
                            <!-- <img loading="lazy" class="transitionImage" [src]="IMG_HOST + marca.logo" alt="marcas{{i}}"> -->
                            <img [src]="IMG_HOST + marca.logo" alt="imagem marca" class="imageCustome bg-warning">
                            <figcaption class="text-center d-flex flex-column justify-content-center align-items-center transitionImage">
                                <h5 class="card-title text-dark">{{marca.marca}}</h5>
                                <a [routerLink]="['/productos', marca.idMarca]" href="#" class="btn btn-warning buttonCard">Ver Productos</a>
                            </figcaption>
                          </figure>   
                          <!-- card transition component  -->
                            </div> 
                          </div>
                    </div>
                </div>
            </div>
        </div>



        <!-- <div *ngFor="let marca of marcas">
            <div class="section-title">
                <h2>{{marca.marca}}</h2>
            </div>
        </div> -->
    </div>
    <br>
</div>

<!-- https://api.whatsapp.com/send?phone= -->
