import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})

export class NosotrosService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getMisionVision() {
    return this.http.get(`${this.API_URI}/nosotros/mision-vision`);
  }

  getNosotros() {
    return this.http.get(`${this.API_URI}/nosotros/nosotros`);
  }

  getValores() {
    return this.http.get(`${this.API_URI}/nosotros/valores`);
  }

  getSlider() {
    return this.http.get(`${this.API_URI}/nosotros/slider`);
  }

}
