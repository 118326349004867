import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})

export class PortadaService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getPortada() {
    return this.http.get(`${this.API_URI}/portada`);
  }

  getPortadaSlider() {
    return this.http.get(`${this.API_URI}/portada/slider`);
  }

}
