import { Component, OnInit } from '@angular/core';
import { VinculacionService } from 'src/app/services/vinculacion/vinculacion.service';
import { environment } from 'src/environments/environment.prod';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ConfiguracionService } from 'src/app/services/configuracion/configuracion.service';

@Component({
  selector: 'app-avisos',
  templateUrl: './avisos.component.html',
  styleUrls: ['./avisos.component.scss']
})
export class AvisosComponent implements OnInit {

  closeResult = '';
  foto;
  public IMG_HOST = environment.API_IMAGEN;
  public cargandoAvisos: boolean = true;
  public sinAvisos: boolean = false;
  public cargandoContenido: boolean = true;
  public contenido: any = {};
  public avisos: any = [];
  public banner;

  constructor(
    private avisos_service: VinculacionService,
    private configuracion_service: ConfiguracionService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.obtenerContenido();
    this.obtenerAvisos();
    this.obtenerBanner();
  }

  obtenerBanner = () => {
    this.configuracion_service.getBanners("'Avisos'").subscribe(respuesta => {
      this.banner = respuesta[0];
    });
  }

  obtenerContenido = () => {
    this.avisos_service.getAvisosContenido().subscribe(respuesta => {
      this.contenido = respuesta[0];
      if (this.contenido) {
        this.cargandoContenido = false;
      } else {
        this.cargandoContenido = false;
      }
    });
  }

  obtenerAvisos = () => {
    this.avisos_service.getAvisos().subscribe(respuesta => {
      this.avisos = respuesta;
      if (this.avisos) {
        this.sinAvisos = false;
        this.cargandoAvisos = false;
      } else {
        this.sinAvisos = true;
        this.cargandoAvisos = false;
      }
    });
  }

  open(content, gal) {
    this.foto = gal;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.foto = gal;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
