<!-- Page banner Area -->
<div class="page-banner" [ngStyle]=" {'background-image': 'url(' + IMG_HOST + banner.rutaImagen + ')' }">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="bannerContainerStyles">
                    <h2>Vinculación</h2>
                    <h3 class="h4">Empresa</h3>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div *ngIf="cargandoContenido">
            <i class='bx bx-loader-circle'></i> Cargando...
        </div>
        <div *ngIf="!cargandoContenido && contenido.contenido !=''" class="row">
            <div class="row h5 text-center p-md-5 p-4 stylesTextVEC mx-auto" [innerHtml]="contenido.contenido | safeHtml"></div>
        </div>
        <div *ngIf="cargandoEmpresas">
            <i class='bx bx-loader-circle'></i> Cargando...
        </div>
        <div *ngIf="sinEmpresas && !cargandoEmpresas" style="text-align: center;">
            <h1><i class='bx bx-wrench'></i></h1>
            <h3>Sin contenido</h3>
        </div>
        <br><br>
        <div *ngIf="!sinEmpresas && !cargandoEmpresas" class="row">
            <div *ngFor="let empresa of empresas" class="w-100">
                <div class="blog-card shadow d-flex flex-md-row flex-column border">
                    <div class="width-customeVE"> 
                    <img loading="lazy" [src]="IMG_HOST + empresa.rutaImagen" alt="image" class="dimensionCustome"> 
                    </div>
                    <div class="blog-text width-customeContentVE">
                        <div class="date">{{empresa.fecha | date:'dd/MM/yyyy'}}</div>
                        <h3>
                            {{empresa.titulo}}
                        </h3>
                        <p class="mb-20" [innerHtml]="empresa.contenido | truncate : 220 : true ">
                        </p>
                        <a [routerLink]="['/detalle-empresa', empresa.idEmpresa]" class="default-btn-two">Leer más</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
