import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfiguracionService } from 'src/app/services/configuracion/configuracion.service';
import { PortadaService } from 'src/app/services/nosotros/portada.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit { 
  @ViewChild("modalFormFooter") modalFooter: ElementRef; 
  @ViewChild("louder") LouderActive : ElementRef; 
  public date = new Date().getFullYear(); 
  public descripcion: any;
  public redes: any = [];
  public WhatsApp_host = 'https://api.whatsapp.com/send?phone='; 
  public louder: boolean = null; 
  private toogleFooter: boolean = false; 


  constructor(
    private descripcion_service: PortadaService,
    private configuracion_service: ConfiguracionService, 
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.obtenerDescripcion();
    this.obtenerRedes();
  } 
  // ----------------------------------modal form footer-------------------------------------
  toogleModal(e){
    this.toogleFooter = !this.toogleFooter  
    if(this.toogleFooter){ 
      this.renderer.addClass(this.modalFooter.nativeElement, "modalTransitionActive");
    }else{ 
      this.renderer.removeClass(this.modalFooter.nativeElement, "modalTransitionActive"); 
    } 
  }
  async formSubmit(e){ 
    e.preventDefault();  
    this.renderer.addClass(this.LouderActive.nativeElement, "activeLouder"); 
    await fetch("https://formsubmit.co/ajax/ventasmerida@apicultoresunidos.com", { 
      method: "POST",
      headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
      },
      body: JSON.stringify({
        nombre: this.getNombre().value, 
        Telefono: this.getTelefono().value, 
        Email: this.getEmail().value, 
        Mensaje: this.getMensaje().value, 
      })
    }).then(Res=>{  
        this.renderer.removeClass(this.LouderActive.nativeElement, "activeLouder"); 
        this.renderer.removeClass(this.modalFooter.nativeElement, "modalTransitionActive"); 
        this.toogleFooter = false;
        this.formModal.reset(); 
    });  
  } 
  
  formModal = new FormGroup({ 
    "nombre": new FormControl("", Validators.required), 
    "telefono": new FormControl("", Validators.required), 
    "mensaje": new FormControl("", Validators.required), 
    "email": new FormControl("", [Validators.required, Validators.email]), 
  }); 
  getNombre(){ 
    return this.formModal.get('nombre') as FormControl; 
  }
  getTelefono(){ 
    return this.formModal.get('telefono') as FormControl; 
  }
  getMensaje(){ 
    return this.formModal.get('mensaje') as FormControl; 
  }
  getEmail(){ 
    return this.formModal.get('email') as FormControl; 
  }
  // ---------------------------modal form----------------------------------

  obtenerDescripcion = () => {
    this.descripcion_service.getPortada().subscribe(respuesta => {
      this.descripcion = respuesta[0];
    });
  }

  obtenerRedes = () => {
    this.configuracion_service.getRedesSociales().subscribe(respuesta => {
      this.redes = respuesta[0];
    });
  }

}
