import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})

export class ContactoService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getContactos() {
    return this.http.get(`${this.API_URI}/contacto`);
  }

  getTelefonos(id) {
    return this.http.get(`${this.API_URI}/contacto/telefonos/${id}`);
  }

  getCorreos(id) {
    return this.http.get(`${this.API_URI}/contacto/correos/${id}`);
  }

  getHorarios(id) {
    return this.http.get(`${this.API_URI}/contacto/horarios/${id}`);
  }

}
