<div class="page-banner mb-3" [ngStyle]=" {'background-image': 'url(' + IMG_HOST + banner.rutaImagen + ')' }">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="bannerContainerStyles d-flex flex-column align-items-center">
                    <h2 class="text-center">Productos</h2>
                    <ul class="d-block p-0">
                        <li class="listItem d-block"><a class="linkStylesBanner text-dark  h3" [routerLink]="['/marcas']">Marcas</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div role="group" ngbRadioGroup name="radioBasic" class=" d-flex ps-5 gap-3 pb-3 customeContainerB" [ngModel]="idMarca" >
    <label *ngFor="let marca of marcas" ngbButtonLabel class="labelCheck customeMarca d-flex justify-content-center align-items-center btn-group {{idMarca === marca.id ? 'active focus': '' }} "
      (click)="cambios(marca.idMarca)" aria-current="page">
      <input ngbButton type="radio" class="btn-check" [value]="marca.idMarca">
      <img [src]="IMG_HOST + marca.logo" alt="marca logotipo" class="w-100 position-absolute rounded-circle">
    </label>
  </div> 

<!-- <input type="radio" class="btn-check" name="options" id="option3" autocomplete="off">
<label class="btn btn-secondary customeCheck" for="option3">Disabled</label>

<input type="radio" class="btn-check" name="options" id="option4" autocomplete="off" >
<label class="btn btn-secondary customeCheck" for="option4">Radio</label> -->



<div class="freight-area freight-area-two pt-3">
    <div class="btn-whatsapp">
        <a [href]="WhatsApp_host + numeroWhats.whatsApp" target="_blank" title="Abrir WhatsApp">
            <img loading="lazy" src="../../../../assets/img/whatsapp-logo-vector.png" alt="WhatsApp">
        </a>
    </div>

    <div *ngIf="cargandoClasificaciones">
        <i class='bx bx-loader-circle'></i> Cargando...
    </div>
    <div *ngIf="sinClasificaciones && !cargandoClasificaciones" style="text-align: center;">
        <h1><i class='bx bx-wrench'></i></h1>
        <h3>Sin contenido</h3>
    </div>
    <div *ngIf="!sinClasificaciones && !cargandoClasificaciones" class="container">
        <div *ngFor="let clasificacion of clasificaciones">
            <div class="section-title">
                <h2>{{clasificacion.clasificacion}}</h2>
            </div>
                <div class="d-flex flex-wrap justify-content-center gap-md-5 gap-2 text-center"> 
                <div *ngFor="let producto of clasificacion.productos; index as i" class="card mb-3 shadow p-2" style="width: 18rem;">
                  <img [src]="IMG_HOST + producto.rutaImagen" class="card-img-top" alt="producto{{i}}">
                  <div class="card-body">
                    <h5 class="card-title">{{producto.nombre}}</h5>
                    <p [innerHtml]="producto.descripcion | safeHtml"></p>
                  </div>
                </div>
            </div>
            <hr>
        </div>
    </div>
    <br>
</div>

