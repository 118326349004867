<!-- Page banner Area -->
<div class="page-banner bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="bannerContainerStyles">
                    <h2>Vinculación</h2>
                    <ul class="p-0">
                        <li class="listItem"><a class="text-dark h4 text-center"  routerLink="/">Inicio/Sociedad</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->
<div class="single-services-area ptb-100 container d-flex justify-content-center">
    <div class="container-per ">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <h3 style="text-align: center">{{detalleSociedad.titulo}}</h3> 
                <!-- titulo XD -->
                <br>
                <div class="service-details-text mx-auto">
                    <div class="row mx-0">
                        <div class="p-0">
                            <div class="testimonials-area">
                                <div class="container-fluid sylesContainerSliderS">
                                    <!-- slider XD -->
                                    <!-- <div class="testimonials-slider owl-carousel owl-theme bg-warning"> 
                                        <div *ngFor="let img of imagenes" class="testimonials-card">
                                            <div class="client-img">
                                                <img loading="lazy" [src]="IMG_HOST + img.rutaImagen"
                                                    style="height: 100%;" alt="image">
                                            </div>
                                        </div>  
                                     </div>  --> 
                                     <!-- newSlider XD -->
                                      <div id="carouselExampleIndicators" class=" carousel slide carousel-fade" data-bs-ride="carousel">
                                        <div class="carousel-inner">
                                          <div *ngFor="let img of imagenes; index as i" class="carousel-item {{i===0 ? 'active' : ''}}">
                                            <img loading="lazy" [src]="IMG_HOST + img.rutaImagen" class="d-block w-100" alt="imagen{{i}}" data-bs-interval="20">
                                          </div>
                                        </div>
                                        <button class="w-auto carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                            <div class="containerButtonCarousel">
                                                <span class="carousel-control-prev-icon colorButtonStyle" aria-hidden="true"></span> 
                                                <span class="visually-hidden">Previous</span>
                                            </div>
                                        </button> 
                                       
                                        <button class="w-auto carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                            <div class="containerButtonCarousel"> 
                                                <span class="carousel-control-next-icon colorButtonStyle" aria-hidden="true"></span>
                                                <span class="visually-hidden">Next</span> 
                                            </div>
                                        </button> 
                                      </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end mt-1 gap-2"> 
                        <a _ngcontent-ylg-c141="" href="https://www.facebook.com/sharer/sharer.php?u=&amp;t=http://localhost:4200/#/detalle-empresa/3" tooltip="Compartir en Facebook" onclick="window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(document.URL) + '&amp;t=' + encodeURIComponent(document.URL)); return false;" ng-reflect-tooltip-value="Compartir en Facebook"><i _ngcontent-iin-c121="" id="facebook" class="bx bxl-facebook"></i></a> 
                        <a _ngcontent-ylg-c141="" href="https://www.whatsapp://send?text=" tooltip="Compartir en WhatsApp" onclick="window.open('https://api.whatsapp.com/send?text=' + encodeURIComponent(document.URL)); return false;" ng-reflect-tooltip-value="Compartir en WhatsApp"> <i id="whatsApp" class='bx bxl-whatsapp'></i> </a> 
                        <a class="w-inline-block social-share-btn tw" href="https://twitter.com/intent/tweet?" target="_blank" title="Tweet" onclick="window.open('https://twitter.com/intent/tweet?text=%20Check%20up%20this%20awesome%20content' + encodeURIComponent(document.title) + ':%20 ' + encodeURIComponent(document.URL)); return false;">  <i id="twitter" class='bx bxl-twitter'></i> </a> 
                    </div>
                    <br>
                    <div class="blog-details-desc word-break">
                        <div class="article-content">
                            <div class="entry-meta">
                                <ul>
                                    <li>
                                        <i class='bx bx-calendar'></i>
                                        {{detalleSociedad.fecha | date:'dd/MM/yyyy'}}
                                    </li>
                                </ul>
                            </div>
                            <!-- texto contenido  -->
                            <p [innerHtml]="detalleSociedad.contenido | safeHtml"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
