import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms'; 
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CompartidoModule } from './components/compartido/compartido.module';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { NosotrosComponent } from './components/pages/nosotros/nosotros.component';
import { AcopiosComponent } from './components/pages/acopios/acopios.component';
import { CertificacionesComponent } from './components/pages/certificaciones/certificaciones.component';
import { ProductosComponent } from './components/pages/productos/productos.component';
import { GaleriaComponent } from './components/pages/galeria/galeria.component';
import { ContactoComponent } from './components/pages/contacto/contacto.component';
import { AvisosComponent } from './components/pages/vinculacion/avisos/avisos.component';
import { EmpresaComponent } from './components/pages/vinculacion/empresa/empresa.component';
import { SociedadComponent } from './components/pages/vinculacion/sociedad/sociedad.component';
import { FloracionesComponent } from './components/pages/floraciones/floraciones.component';
import { DetalleEmpresaComponent } from './components/pages/vinculacion/detalle-empresa/detalle-empresa.component';
import { DetalleSociedadComponent } from './components/pages/vinculacion/detalle-sociedad/detalle-sociedad.component';
import { ParticlesModule } from 'ngx-particle';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AvisosPrivacidadComponent } from './components/pages/avisos_privacidad/avisos.component';
import { MarcasComponent } from './components/pages/productos/marcas.component';


@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    NavbarComponent,
    FooterComponent,
    ErrorComponent,
    NosotrosComponent,
    AcopiosComponent,
    CertificacionesComponent,
    FloracionesComponent,
    MarcasComponent,
    ProductosComponent,
    GaleriaComponent,
    ContactoComponent,
    AvisosComponent,
    EmpresaComponent,
    DetalleEmpresaComponent,
    SociedadComponent,
    DetalleSociedadComponent,
    AvisosPrivacidadComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    CompartidoModule,
    ParticlesModule,
    NgbModule,
    ReactiveFormsModule, 
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
