import { Component, OnInit } from '@angular/core';
import { ContactoService } from 'src/app/services/contacto/contacto.service';
import { MessageService } from 'src/app/services/envio-email.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {

  public cargandoContactos: boolean = true;
  public sinContactos: boolean = false;
  public contactos;
  public telefonos;
  public correos;
  public MAIL = 'mailto:'

  constructor(
    private contacto_service: ContactoService,
    private envio_service: MessageService
  ) { }

  ngOnInit(): void {
    this.obtenerContactos();
  }

  obtenerContactos = () => {
    this.contacto_service.getContactos().subscribe(respuesta => {
      this.contactos = respuesta;
      if (this.contactos) {
        this.sinContactos = false;
        this.cargandoContactos = false;
        this.contactos.forEach((element) => {
          this.contacto_service.getTelefonos(element.idContacto).subscribe(response => {
            element.telefonos = response;
          })
          this.contacto_service.getCorreos(element.idContacto).subscribe(response => {
            element.correos = response;
          })
          this.contacto_service.getHorarios(element.idContacto).subscribe(response => {
            element.horarios = response;
          })
        });
      } else {
        this.sinContactos = true;
        this.cargandoContactos = false;
      }
    });
  }


  contactForm(form) {
    var date = new Date();
    const formatDate = (date) => {
      let formatted_date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      return formatted_date;
    }
    if (form.nombre != '' && form.correo != '' && form.asunto != '' && form.telefono != '' && form.mensaje != '') {
      form.fecha = formatDate(date);
      this.envio_service.envioCorreo(form).subscribe((response) => { });
      if (form) {
        this.envio_service.guardarEmail(form).subscribe((response) => {
          Swal.fire(
            '¡Hecho!',
            'Mensaje enviado',
            'success'
          )
          if (response) {
            setTimeout(
              function () { window.location.reload(); }, 3000
            );
          }
        })
      }
    } else {
      Swal.fire(
        'Verifica tus datos',
        '(Nombre. Correo. Teléfono. Asunto. Mensaje)',
        'error'
      )
    }
  }

}
