<!-- Footer bottom Area -->
<div class="footer-bottom">
    <div class="container">
        <!-- --------------------------------modal------------------------------ -->
        <br>
        <div class="d-flex justify-content-center mb-2 position-relative"> 
            <button class="btn btn-primary" (click)="toogleModal($event)">¡Contáctanos!</button>
            <div #modalFormFooter class="modalTransitio bg-warning p-3 pt-0"> 
                <div class="p-0 pt-2 d-flex justify-content-end fontCustome"><button (click)="toogleModal($event)" class="btn btn-sm p-0 d-flex"> <i class='bx bx-x h4 bg-danger text-light roudend-3'></i> </button></div>
                <h2 class="text-center h4 pb-3">¡Contáctanos!</h2>
                <form (submit)="formSubmit($event)" [formGroup]="formModal"> 
                    <div class="from-group mb-2"> 
                        <label for="name" class="form-label">Nombre</label> 
                        <input formControlName="nombre" id="nombre" type="text" class="form-control mb-1">
                        <p *ngIf="!getNombre().valid && !getNombre()?.untouched" class="text-danger p-0 text-right d-flex" role="alert ">El campo es requerido</p>
                    </div> 
                    <div class="from-group mb-2"> 
                        <label for="tel" class="form-label">Telefono</label> 
                        <input formControlName="telefono" id="telefono" type="text" class="form-control mb-1"> 
                        <p *ngIf="!getTelefono().valid && !getTelefono()?.untouched" class="text-danger p-0 text-right d-flex" role="alert ">El campo es requerido</p>

                    </div>
                    <div class="form-grop mb-2"> 
                        <label for="email" class="form-label">Email</label> 
                        <input formControlName="email" type="email" class="form-control mb-1"> 
                        <p *ngIf="getEmail().errors?.['required'] && !getEmail()?.untouched" class="text-danger p-0 text-right d-flex"> El campo es requerido </p> 
                        <p *ngIf="getEmail().errors?.['email']" class="text-danger p-0 text-right d-flex"> Ingresa un email valido </p>

                    </div>
                    <div class="from-group mb-3">  
                        <label for="mensaje" class="form-label">Mensaje</label> 
                        <textarea formControlName="mensaje" class="form-control textArea-style mb-1" id="mensaje" cols="30" rows="10" placeholder="mensaje..."></textarea>
                        <p *ngIf="!getMensaje().valid && !getMensaje()?.untouched" class="text-danger p-0 text-right d-flex" role="alert ">El campo es requerido</p>
                    </div>
                    <div class="d-flex flex-row-reverse justify-content-between p-2"> 
                        <div class="d-flex justify-content-center align-items-center"> 
                            <button [disabled]="!formModal.valid" class="btn btn-primary button-height m-0">Enviar</button> 
                        </div> 
                        <div #louder class="loader">
                            <span>Enviando</span>
                        </div> 
                    </div>
                </form> 
            </div>
        </div>
        <ul class="footer-socials">
            <li *ngIf="redes.facebook && redes.facebook != ''">
                <a [href]="redes.facebook" target="_blank">
                    <i id="facebook" class='bx bxl-facebook'></i>
                </a>
            </li>
            <li *ngIf="redes.instagram && redes.instagram != ''">
                <a [href]="redes.instagram" target="_blank">
                    <i id="instagram" class='bx bxl-instagram-alt'></i>
                </a>
            </li>
            <li *ngIf="redes.pinterest && redes.pinterest != ''">
                <a [href]="redes.pinterest" target="_blank">
                    <i id="pinterest" class='bx bxl-pinterest'></i>
                </a>
            </li>
            <li *ngIf="redes.twitter && redes.twitter != ''">
                <a [href]="redes.twitter" target="_blank">
                    <i id="twitter" class='bx bxl-twitter'></i>
                </a>
            </li>
            <li *ngIf="redes.tiktok && redes.tiktok != ''">
                <a [href]="redes.tiktok" target="_blank">
                    <i id="tiktok" class='bx bxl-tiktok'></i>
                </a>
            </li>
            <li *ngIf="redes.youtube && redes.youtube != ''">
                <a [href]="redes.youtube" target="_blank">
                    <i id="youtube" class='bx bxl-youtube'></i>
                </a>
            </li>
            <li *ngIf="redes.whatsApp && redes.whatsApp != ''">
                <a [href]="WhatsApp_host + redes.whatsApp" target="_blank">
                    <i id="whatsApp" class='bx bxl-whatsapp'></i>
                </a>
            </li>
        </ul>
        <!-- <br> -->
        <div class="row">
            <div class="col-md-4">
                <p>Copyright @{{date}} . All Rights Reserved</p>
            </div>
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <a routerLink="/avisos-privacidad">
                    <p>Avisos de privacidad</p>
                </a>
            </div>
        </div>
    </div>
</div>
<!-- End Footer bottom Area -->

<!-- Go Top -->
<!-- <div class="go-top">
    <i class='bx bx-chevrons-up'></i>
</div> -->
<!-- End Go Top -->