<div class="img-portada">
    <!-- <div class="hero-slider owl-carousel owl-theme" *ngIf="!dimensionPantalla">
        <div *ngFor="let slidPortada of portadaSlider" class="hero-slider-item"
            [ngStyle]="{'background-image': 'url(' + IMG_HOST + slidPortada.rutaImagen + ')'}">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="banner-content">

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div *ngIf="dimensionPantalla">
        <br><br><br><br>
        <div class="testimonials-area">
            <div class="container">
                <div class="testimonials-slider owl-carousel owl-theme">
                    <div *ngFor="let slidPortada of portadaSlider">
                        <div class="client-img">
                            <img loading="lazy" [src]="IMG_HOST + slidPortada.rutaImagen" style="height: 100%;"
                                alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>  -->
    
    <!-- <div>
        <owl-carousel-o [options]="homeSlides">
            <ng-template carouselSlide *ngFor="let slidPortada of portadaSlider">
                <div  [ngStyle]="{'background-image': 'url(' + IMG_HOST + slidPortada.rutaImagen + ')'}">
                </div>
            </ng-template>
        </owl-carousel-o>
    </div> -->

    <div id="carouselExampleIndicators" class="carousel slide carousel-fade carouselPStyles" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div *ngFor="let img of portadaSlider; index as i" class="carousel-item {{i===0 ? 'active' : ''}} container-ratio-custome">
            <img loading="lazy" [src]="IMG_HOST + img.rutaImagen" class="d-block w-100 object-fit-cover" alt="imagen{{i}}" data-bs-interval="20">
          </div>
        </div>
        <button class="w-auto carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
            <div class="containerButtonCarousel"> 
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span> 
            </div>
        </button>
        <button class=" w-auto carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next"> 
            <div class="containerButtonCarousel"> 
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span> 
            </div>
        </button>
      </div>  
    <div id="nosotros">
        <br><br><br><br>
        <div class="about-area pb-70">
            <div class="container">
                <div *ngIf="cargandoBienvenida">
                    <i class='bx bx-loader-circle'></i> Cargando...
                </div>
                <div *ngIf="sinBienvenida && !cargandoBienvenida" style="text-align: center;">
                    <h1><i class='bx bx-wrench'></i></h1>
                    <h3>Sin contenido</h3>
                </div>
                <div class="row justify-content-center"> 
                <div *ngIf="!sinBienvenida && !cargandoBienvenida" class="widthRes">
                    <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                        <div class="about-contant">
                            <div class="about-text" [innerHtml]="bienvenida.contenido | safeHtml">
                            </div>
                        </div>
                    </div> 
                    <!-- <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                        <div class="testimonials-area">
                            <div class="container">
                                <div class="testimonials-slider owl-carousel owl-theme">
                                    <div *ngFor="let img of slider" class="testimonials-card">
                                        <div class="client-img">
                                            <img loading="lazy" [src]="IMG_HOST + img.rutaImagen" style="height: 100%;"
                                                alt="image">
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        </div>    
                    </div>   -->
                </div>   
                <div id="carouselExample2" class="carouselW carousel slide carousel-fade" data-bs-ride="carousel"> 
                    <div class="carousel-inner">
                      <div *ngFor="let img2 of slider; index as i2" class="carousel-item {{i2===0 ? 'active' : ''}} container-ratio-custome">
                        <img loading="lazy" [src]="IMG_HOST + img2.rutaImagen" class="d-block w-100 object-fit-cover " alt="imagen{{i2}}" data-bs-interval="100">
                      </div>
                    </div>
                    <button class="carousel-control-prev buttonFlex" type="button" data-bs-target="#carouselExample2" data-bs-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next buttonFlex" type="button" data-bs-target="#carouselExample2" data-bs-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
            </div> 
        </div> 
    </div> 

    <div class="mv-area ptb-30">
        <div class="container">
            <div *ngIf="cargandoMisionVision">
                <i class='bx bx-loader-circle'></i> Cargando...
            </div>
            <div *ngIf="sinMisionVision && !cargandoMisionVision" style="text-align: center;">
                <h1><i class='bx bx-wrench'></i></h1>
                <h3>Sin contenido</h3>
            </div>
            <div *ngIf="!sinMisionVision && !cargandoMisionVision" class="row">
                <div *ngFor="let mv of misionVision" class="col-lg-6 col-md-6">
                    <div class="about-info-card">
                        <h3>{{mv.seccion}}</h3>
                        <div [innerHtml]="mv.contenido | safeHtml"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="about-info-area ptb-30">
        <div class="container">
            <div *ngIf="cargandoValores">
                <i class='bx bx-loader-circle'></i> Cargando...
            </div>
            <div *ngIf="sinValores && !cargandoValores" style="text-align: center;">
                <h1><i class='bx bx-wrench'></i></h1>
                <h3>Sin contenido</h3>
            </div>
            <div *ngIf="!sinValores && !cargandoValores" class="row align-items-center">
                <div class="col-lg-12 col-md-12">
                    <div class="about-info-card">
                        <h3>Valores Corporativos</h3>
                        <div class="pb-custome">
                            <!-- <div class="box-valor col-lg-4 col-md-4 col-sm-12 col-xs-12" *ngFor="let valor of valores">
                                <div class=" bg-warning border border-2 caja background-hect px-5">
                                    <p class="text-dark fw-bold">{{valor.nombre}}</p>
                                    <p class="text-dark" >{{valor.contenido}}</p>
                                </div>
                            </div> --> 
                            <ul class="honeycomb" lang="es">
                                <li *ngFor="let valor of valores" class="honeycomb-cell">
                                  <div class="honeycomb-cell__title"> {{ valor.nombre}} </div>
                                  <div class="honeycomb-cell__image text-dark font-size"> 
                                    <p>{{valor.contenido}}</p>  
                                  </div>
                                </li>
                         
                                <li class="honeycomb-cell honeycomb__placeholder"></li>
                              </ul>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mv-area ptb-30">
        <div class="container">
            <div class="row align-items-center justify-content-center ">
                <div *ngIf="cargandoNosotros">
                    <i class='bx bx-loader-circle'></i> Cargando...
                </div>
                <div *ngIf="sinNosotros && !cargandoNosotros" style="text-align: center;">
                    <h1><i class='bx bx-wrench'></i></h1>
                    <h3>Sin contenido</h3>
                </div>
                <div *ngIf="!sinNosotros && !cargandoNosotros" class="row">
                    <div *ngFor="let n of nosotros" class="col-lg-12 col-md-12">
                        <div class="about-info-card pb-2 d-flex flex-column mx-auto">
                            <h3 class="ContainerSections text-md-start text-center">{{n.seccion}}</h3>
                            <div class="h5 text-center p-md-5 p-4 background-panal" [innerHtml]="n.contenido | safeHtml"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
