<!-- Page banner Area -->
<div class="banner-seccion" [ngStyle]=" {'background-image': 'url(' + IMG_HOST + contenido.rutaImagen +')' }">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="bannerContainerStyles">
                    <h2>Floraciones</h2>
                </div>
            </div>
        </div>
    </div>
    <br><br>
</div>
<!-- End Page banner Area -->
<div class="blog-details-area ptb-30">
    <div class="container">

        <div *ngIf="cargandoPeriodos">
            <i class='bx bx-loader-circle'></i> Cargando...
        </div>

        <div *ngIf="sinPeriodos && !cargandoPeriodos" style="text-align: center;">
            <h1><i class='bx bx-wrench'></i></h1>
            <h3>Sin contenido</h3>
        </div>

        <div *ngIf="!sinPeriodos && !cargandoPeriodos">
            <!-- <div class="row align-items-center">
                <div class="col-lg-1 md-1"></div>
                <div class="col-lg-10 col-md-10">
                    <div class="blog-details-desc">
                        <div class="article-image">
                            <img loading="lazy" [src]="IMG_HOST + contenido.rutaImagen" alt="image">
                        </div>
                    </div>
                </div>
                <div class="col-lg-1 md-1"></div>
            </div>
            <br><br> -->

            <div class="row align-items-center" *ngFor="let periodo of periodos; let i = index">
                <div class="col-12">
                    <div class="frequently-accrodion">
                        <br>
                        <div class="row justify-content-around ">
                            <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 mb-4 mb-md-0">
                                <div class="row">
                                    <h3 class="">{{periodo.periodo}}</h3>
                                </div>
                                <div [innerHtml]="periodo.contenido | safeHtml">
                                </div>
                            </div>
                            <div class="col-md-5 col-sm-12 col-12">
                                <div class="container p-0 ">
                                    <div #carouselXD id="slider{{i}}" class="carousel slide carousel-fade" data-bs-ride="carousel">
                                        <div class="carousel-inner">
                                          <div *ngFor="let img3 of periodo.imagenes; index as i3" class="carousel-item {{i3===0 ? 'active' : ''}}">
                                            <img loading="lazy" [src]="IMG_HOST + img3.rutaImagen" class="d-block w-100" alt="imagen{{i3}}" data-bs-interval="100">
                                          </div>
                                        </div>
                                        <button #buttonSlider class="carousel-control-prev buttonFlex buttonSliderPrev" type="button" data-bs-slide="prev">
                                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                          <span class="visually-hidden">Previous</span>
                                        </button>
                                        <button class="carousel-control-next buttonFlex buttonSliderNext" type="button"  data-bs-slide="next">
                                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                          <span class="visually-hidden">Next</span>
                                        </button>
                                      </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row px-5">
                            <div *ngFor="let floracion of periodo.floraciones"
                                class="choose-area col-md-12 col-lg-3 col-sm-12 col-xs-12">
                                <div class="choose-contant mb-3 col-12">
                                    <div class="choose-card blog-details-desc shadow">
                                        <h3>{{floracion.nombre}}</h3>
                                        <div class="article-content">
                                            <div class="entry-meta">
                                                <ul>
                                                    <li>
                                                        <i class='bx bx-map-pin'></i>
                                                        <span>Zona</span>
                                                        {{floracion.zona}}
                                                    </li>
                                                    <li>
                                                        <i class='bx bxs-thermometer'></i>
                                                        <span>Humedad</span>
                                                        {{floracion.humedad}}
                                                    </li>
                                                    <li>
                                                        <i class='bx bxs-droplet-half'></i>
                                                        <span>Color</span>
                                                        {{floracion.color}}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <hr>
                </div>
            </div>

        </div>

    </div>
</div>
