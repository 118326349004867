import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})

export class VinculacionService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getAvisosContenido() {
    return this.http.get(`${this.API_URI}/vinculacion/avisos/contenido`);
  }

  getAvisos() {
    return this.http.get(`${this.API_URI}/vinculacion/avisos`);
  }

  getEmpresasContenido() {
    return this.http.get(`${this.API_URI}/vinculacion/empresas/contenido`);
  }

  getEmpresas() {
    return this.http.get(`${this.API_URI}/vinculacion/empresas`);
  }

  getDetalleEmpresa(id) {
    return this.http.get(`${this.API_URI}/vinculacion/detalleempresa/${id}`);
  }

  getImagenesEmpresa(id) {
    return this.http.get(`${this.API_URI}/vinculacion/empresa/${id}`);
  }

  getSociedadesContenido() {
    return this.http.get(`${this.API_URI}/vinculacion/sociedades/contenido`);
  }

  getSociedades() {
    return this.http.get(`${this.API_URI}/vinculacion/sociedades`);
  }

  getDetalleSociedad(id) {
    return this.http.get(`${this.API_URI}/vinculacion/detallesociedad/${id}`);
  }

  getImagenesSociedad(id) {
    return this.http.get(`${this.API_URI}/vinculacion/sociedad/${id}`);
  }


}
