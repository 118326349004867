import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})

export class MessageService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  envioCorreo(body) {
    return this.http.post(`${this.API_URI}/formulario`, body);
  }

  guardarEmail(body) {
    return this.http.post(`${this.API_URI}/formulario-contacto`, body);
  }

}

